import {
  RiAdvertisementLine,
  RiMoneyDollarBoxLine,
  RiNotification2Fill,
  RiNotification2Line,
  RiPrinterLine,
} from 'react-icons/ri';
import {AiOutlineHome} from 'react-icons//ai';

import {AttachMoneyRounded, SettingsOutlined} from '@mui/icons-material';

const routesConfig = [
  {
    id: 'app',
    title: 'Application',
    messageId: 'sidebar.application',
    type: 'group',
    children: [
      {
        id: 'e-commerce',
        title: 'E-Commerce',
        messageId: 'sidebar.app.dashboard.homePage',
        type: 'item',
        icon: <AiOutlineHome />,
        url: '/dashboards/homePage',
      },
    ],
  },

  /////////
  // product management
  {
    id: 'productManagements',
    title: 'Product Managements',
    messageId: 'sidebar.productManagements',
    type: 'collapse',

    children: [
      {
        id: 'products',
        title: 'Products',
        messageId: 'sidebar.ecommerce.products',
        type: 'item',
        url: '/product-management/products',
      },
      {
        id: 'HideenProducts',
        title: 'HideenProducts',
        messageId: 'sidebar.ecommerce.HiddenProducts',
        type: 'item',
        url: '/product-management/HiddenProducts',
      },
      {
        id: 'SalesProducts',
        title: 'SalesProducts',
        messageId: 'sidebar.ecommerce.SalesProducts',
        type: 'item',
        url: '/product-management/SalesProducts',
      },
      {
        id: 'SoldoutProducts',
        title: 'SoldoutProducts',
        messageId: 'sidebar.ecommerce.SoldoutProducts',
        type: 'item',
        url: '/product-management/SoldoutProducts',
      },
      {
        id: 'damagedProducts',
        title: 'Damaged Products',
        messageId: 'sidebar.product-management.damaged-products',
        type: 'item',
        url: '/product-management/damaged-product',
      },
    ],
  },
  //////////////////////////////
  {
    id: 'salesManagements',
    title: 'Sales Managements',
    messageId: 'sidebar.salesManagements',
    type: 'collapse',

    children: [
      {
        id: 'ProductPageSetting',
        title: 'Product Event',
        messageId: 'sidebar.app.dashboard.ProductEventPageSetting',
        type: 'item',
        icon: <RiAdvertisementLine />,
        url: '/websiteManagement/ProductsPageSetting',
      },
      {
        id: 'EventSalesProducts',
        title: 'EventSalesProducts',
        messageId: 'sidebar.ecommerce.EventSalesProducts',
        type: 'item',
        url: '/product-management/EventsSalesProducts',
      },
    ],
  },
  //////////////////////////////
  //
  {
    id: 'orders',
    title: 'Orders Mangment',
    messageId: 'sidebar.ordersMangment',
    type: 'collapse',
    children: [
      {
        id: 'Addorder',
        title: 'Add new ORder',
        messageId: 'sidebar.management.AddOrder',
        type: 'item',

        url: '/Orders/AddOrder',
      },
      {
        id: 'allOrders',
        title: 'All Orders',
        messageId: 'sidebar.orders.allOrders',
        type: 'item',
        url: '/Orders/AllOrders',
      },
      {
        id: 'orders',
        title: 'Pending Orders',
        messageId: 'eCommerce.pendingOrders',
        type: 'item',
        url: '/Orders/orders',
      },
      {
        id: 'inProgressOrders',
        title: 'In Progress Orders',
        messageId: 'sidebar.orders.inProgressOrdres',
        type: 'item',
        url: '/Orders/InProgressOrders',
      },
      {
        id: 'ReadyToDeliveryOrders',
        title: 'Ready To Delivery Orders',
        messageId: 'ReadyToDeliveryOrders',
        type: 'item',
        url: '/Orders/ReadyToDeliveryOrders',
      },
      {
        id: 'inDeliveryOrders',
        title: 'In Delivery Orders',
        messageId: 'sidebar.orders.inDeliveryOrdres',
        type: 'item',
        url: '/Orders/InDeliveryOrders',
      },
      {
        id: 'DeliveredOrders',
        title: 'DeliveredO Orders',
        messageId: 'sidebar.orders.Delivered',
        type: 'item',
        url: '/Orders/DeliveredOrders',
      },
      {
        id: 'completedOrders',
        title: 'Completed Orders',
        messageId: 'sidebar.orders.completed',
        type: 'item',
        url: '/Orders/CompletedOrders',
      },
      {
        id: 'InReviewOrders',
        title: 'In Review Orders',
        messageId: 'sidebar.orders.InReviewOrders',
        type: 'item',
        url: '/Orders/InReviewOrders',
      },
      {
        id: 'CanceledOrders',
        title: 'Canceled Orders',
        messageId: 'sidebar.orders.CanceledOrders',
        type: 'item',
        url: '/Orders/CanceledOrders',
      },
    ],
  },
  ////
  {
    id: 'employeesManagements',
    title: 'Employees Managements',
    messageId: 'sidebar.employeeManagements',
    type: 'collapse',

    children: [
      {
        id: 'employee',
        title: 'Employee',
        messageId: 'sidebar.ecommerce.employee',
        type: 'item',
        url: '/management/employees',
      },
      {
        id: 'oldemployee',
        title: 'Old Employee',
        messageId: 'sidebar.ecommerce.oldEmployee',
        type: 'item',
        url: '/management/oldEmployee',
      },
    ],
  },

  ///////// mangemnts
  {
    id: 'customerMangments',
    title: 'Mangments',
    messageId: 'sidebar.customerMangments',
    type: 'collapse',

    children: [
      {
        id: 'customers',
        title: 'Customers',
        messageId: 'sidebar.ecommerce.customers',
        type: 'item',
        url: '/management/customers',
      },
    ],
  },
  {
    id: 'financial management',
    title: 'financialMangments',
    messageId: 'sidebar.finance.financialmanagement',
    type: 'collapse',

    children: [
      {
        id: 'Debtors',
        title: 'Debtors',
        messageId: 'sidebar.finance.debtorsMangement',
        type: 'item',
        url: '/management/debtors',
      },
      {
        id: 'financial management',
        title: 'financialMangments',
        messageId: 'sidebar.finance.ChecksMangement',
        type: 'collapse',

        children: [
          {
            id: 'Checks',
            title: 'Checks',
            messageId: 'sidebar.finance.Checks',
            type: 'item',
            icon: <RiMoneyDollarBoxLine />,
            url: '/management/Checks',
          },
          {
            id: 'Checks Report',
            title: 'Checks Report',
            messageId: 'sidebar.finance.ChecksReport',
            type: 'item',
            icon: <RiPrinterLine />,
            url: '/management/ChecksReport',
          },
        ],
      },
    ],
  },
  ////
  {
    id: 'runningCostManagements',
    title: 'Running Cost Managements',
    messageId: 'sidebar.runningCostManagements',
    type: 'collapse',

    children: [
      {
        id: 'runningCost',
        title: 'Running cost',
        messageId: 'sidebar.ecommerce.runningCost',
        type: 'item',
        url: '/management/runningcost',
      },
      {
        id: 'DeletedrunningCost',
        title: 'Deleted Running cost',
        messageId: 'sidebar.ecommerce.DeletedrunningCost',
        type: 'item',
        url: '/management/deletRuningCost',
      },
    ],
  },
  //////////////////////////
  {
    id: 'feedBackManagements',
    title: 'FeedBack  Managements',
    messageId: 'sidebar.FeedBack',
    type: 'collapse',

    children: [
      {
        id: 'feedBack',
        title: 'Feedback',
        messageId: 'sidebar.ecommerce.mail',
        type: 'item',
        url: '/management/Mail',
      },
      {
        id: 'feedBack',
        title: 'Feedback',
        messageId: 'sidebar.ecommerce.Readedmail',
        type: 'item',
        url: '/management/ReadedMail',
      },
    ],
  },

  ///////////////////////////
  {
    id: 'sellingPointManagements',
    title: 'Selling Point Managements',
    messageId: 'sidebar.sellingPointManagements',
    type: 'collapse',

    children: [
      {
        id: 'sellingpoint',
        title: 'Selling Point',
        messageId: 'sidebar.ecommerce.sellingpoint',
        type: 'item',
        url: '/management/Sellingpoint',
      },
      {
        id: 'oldsellingpoint',
        title: ' Selling Point Old',
        messageId: 'sidebar.ecommerce.oldsellingpoint',
        type: 'item',
        url: '/management/OldSellingpoint',
      },
    ],
  },
  {
    id: 'TransactionManagements',
    title: 'Transaction  Managements',
    messageId: 'sidebar.TransactionManagements',
    type: 'collapse',

    children: [
      {
        id: 'Transaction',
        title: 'Transaction',
        messageId: 'sidebar.ecommerce.Transaction',
        type: 'item',
        url: '/management/Transaction',
      },
      {
        id: 'Transaction',
        title: 'Transaction',
        messageId: 'sidebar.ecommerce.TransactionSuccess',
        type: 'item',
        url: '/management/TransactionSuccess',
      },
    ],
  },
  //////////////
  {
    id: 'reports',
    title: 'Reports',
    messageId: 'sidebar.reports',
    type: 'collapse',

    children: [
      {
        id: 'financialReports',
        title: 'Financial reports',
        messageId: 'sidebar.reports.financialReports',
        type: 'item',
        url: '/reports/financialReports',
      },

      {
        id: 'orderReports',
        title: 'Order reports',
        messageId: 'sidebar.reports.orderReports',
        type: 'item',
        url: '/reports/orderreports',
      },
      // {
      //   id: 'employeeReport',
      //   title: 'Employee Report',
      //   messageId: 'sidebar.reports.employeesReports',
      //   type: 'item',
      //   url: '/reports/employeesreports',
      // },
      {
        id: 'warehouseReports',
        title: 'Warehouse Reports',
        messageId: 'sidebar.reports.warehouseReports',
        type: 'item',
        url: '/reports/warehousereports',
      },
      {
        id: 'VendorwarehouseReports',
        title: 'Warehouse Reports',
        messageId: 'sidebar.reports.VendorwarehouseReports',
        type: 'item',
        url: '/reports/warehouseVendorReports',
      },
      {
        id: 'rinningCostReports',
        title: 'Rinning Cost Reports',
        messageId: 'sidebar.ecommerce.runningCost',
        type: 'item',
        url: '/reports/runningCostReports',
      },
    ],
  },
  /////////////////// marketing section
  {
    id: 'marketingSection',
    title: 'marketingSection',
    messageId: 'sidebar.marketingSection',
    type: 'collapse',

    children: [
      {
        id: 'facebookMarketing',
        title: 'Facebook Data Sheet',
        messageId: 'sidebar.reports.facebookDataSheet',
        type: 'item',
        url: '/marketing/FacebookData',
      },
    ],
  },
  ////////////////// website mangment
  {
    id: 'website',
    title: 'Website Mangment',
    messageId: 'sidebar.websiteMangment',
    type: 'collapse',
    children: [
      {
        id: 'banners',
        title: 'Banners',
        messageId: 'sidebar.app.dashboard.banners',
        type: 'item',
        icon: <RiAdvertisementLine />,
        url: '/websiteManagement/Banners',
      },
      {
        id: 'MainCategory',
        title: 'Main Category',
        messageId: 'sidebar.app.dashboard.MainCategory',
        type: 'item',
        icon: <RiAdvertisementLine />,
        url: '/websiteManagement/MainCategory',
      },
      {
        id: 'copoun',
        title: 'coupon code Generate',
        messageId: 'sidebar.app.dashboard.copoun',
        type: 'item',
        icon: <AttachMoneyRounded />,
        url: '/websiteManagement/Copoun',
      },
      {
        id: 'WebsiteHomePageSetting',
        title: 'Main Category',
        messageId: 'sidebar.app.dashboard.HomePageSetting',
        type: 'item',
        icon: <RiAdvertisementLine />,
        url: '/websiteManagement/websiteHomePageSetting',
      },
      {
        id: 'notifications',
        title: 'notifications',
        messageId: 'common.notificationsMangment',
        type: 'collapse',
        icon: <RiNotification2Fill />,
        children: [
          {
            id: 'financialMangment',
            title: 'financialMangment',
            messageId: 'common.financialMangment',
            type: 'item',
            icon: <RiNotification2Line />,
            url: 'notifications/financialMangment',
          },
        ],
      },
      {
        id: 'database',
        title: 'Database Mangment',
        messageId: 'sidebar.databaseMangment',
        type: 'collapse',
        children: [
          {
            id: 'banners',
            title: 'Banners',
            messageId: 'sidebar.app.dashboard.intilizeEventFlag',
            type: 'item',
            icon: <SettingsOutlined />,
            url: '/databaseMangement/databaseProductEvent',
          },
        ],
      },
    ],
  },
  ///////////////////

  ///////////////////////////////
];
export default routesConfig;
